.address  {
    margin-bottom: 1em;
    > .map-link {
        margin-top: 1em;
    }
    > .phone {
        margin-top: 1em;
    }
    .icon {
        margin-right: 0.5em;
        margin-bottom: -0.1em;
    }
}