@use '../../../assets/scss/palette.scss'; 

.move-button {
    width: 100%;
    margin-bottom: 1em;
}

.action-message {
    font-size: 1.2em;
    text-align: center;
    user-select: none;
    > strong {
        margin-left: 0.2em;
    }
}

.location {
    margin: 0.2em 0 0.5em;
    padding: 1em;
    border: 1px dashed #ccc;
    text-align: center;
    font-size: 1.5em;
    user-select: none;
    &.scanned {
        color: darken(palette.$body-text-color, 20%);
        background-color: lighten(palette.$ok-color, 30%);
        font-size: 1.5em;
        font-weight: 700;
    }
}

.box-list  {
    > .box {
        margin: 0.2em 0;
        padding: 1em;
        background-color: #e7e7e7;
        &.scanned {
            background-color: lighten(palette.$warn-color, 30%);
        }
        &.moved {
            background-color: lighten(palette.$ok-color, 30%);
        }
        > .barcode {
            font-size: 2.2em;
            text-align: center;
            color: darken(palette.$body-text-color, 20%);
            pointer-events: none;
            user-select: none;
            > strong {
                margin-left: 0.2em;
            }
        }
        > .current-location {
            text-align: center;
            font-size: 1.2em;
        }
    }
}