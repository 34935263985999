@use '../../assets/scss/palette.scss'; 

#incoming {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1em;
    height: 100%;
    > div {
        background-color: #efefef;
        height: 100%;
        padding: 1em;
        box-sizing: border-box;
        overflow-y: scroll;
        > h2 {
            margin-top: 0;
            line-height: 1;
        }
        .courier {
            margin-bottom: 1em;
            &.ok {
                color: darken(palette.$ok-color, 5%);
            }
            > h4 {
                margin: 0;
            }
        }
        .store {
            &.ok {
                color: darken(palette.$ok-color, 5%);
            }
        }
        .icon {
            float: right;
        }
    }
}