@use '../../../assets/scss/palette.scss' as palette;

#dialog {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    transition: all ease-in-out 0.3s 0.3s;
    &.hide {
        opacity: 0;
        pointer-events: none;
        > .inner {
            > .modal {
                margin-top: -200%;
            }
        }
    }
    > .inner {
        position: relative;
        display: grid;
        width: 100%;
        max-width: 500px;
        height: 100%;
        margin: auto;
        padding: 2em;
        box-sizing: border-box;
        overflow: hidden;
        background-color: transparentize(darken(palette.$body-text-color, 20%), 0.3);
        text-align: center;
        align-content: center;
        > .modal {
            width: 80%;
            margin: -40% auto auto;
            padding: 1em;
            background-color: #fff;
            box-shadow: lighten(#000, 20%) 2px 2px 15px;
            transition: all ease-in-out 0.3s;
            button {
                margin: 0 0.2em;
            }
        }
    }
}