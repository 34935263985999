@use '../../../assets/scss/palette.scss'; 

.accordion  {
    > h3 {
        position: relative;
        margin: 0;
        padding: 0.5em 0;
        cursor: pointer;
        > span {
            pointer-events: none;
            &.extra {
                position: absolute;
                right: 2.2em;
                color: #fff;
                font-size: 0.8em;
                font-weight: 100;
                > span {
                    padding: 0 0.4em;
                    background-color: palette.$body-text-color;
                }
                > .icon {
                    padding: 0.3em 0.4em;
                    &.ok {
                        background-color: palette.$ok-color;
                    }
                }
            }
        }
        > .icon {
            position: absolute;
            right: 0;
            top: 0.6em;
        }
    }
    > div {
        max-height: 0;
        opacity: 0;
        pointer-events: none;
        transition: max-height ease-in-out 0.3s, opacity ease-in-out 0.3s;
        &.shown {
            margin-bottom: 3em;
            max-height: 1000px;
            opacity: 1;
            pointer-events: all;
            transition: max-height ease-in-out 0.3s 0.3s, opacity ease-in-out 0.3s 0.3s;
        }
    }
}