footer {
    position: fixed;
    bottom: 0;
    left: 0;
    height: auto;
    width: 100%;
    > .inner {
        width: 100%;
        max-width: 500px;
        height: 100%;
        margin: auto;
        box-sizing: border-box;
        background-color: #e7e7e7;
        h4 {
            height: 1.5em;
            margin: 0;
            padding: 0 1em;
            text-align: center;
            font-weight: 100;
        }
        .button-container {
            display: grid;
            &.single {
                grid-template-columns: 1fr;
            }
            &.double {
                grid-template-columns: repeat(2, 1fr);
            }
            > button {
                height: 100%;
                border: 1px solid #fff;
            }
        }
    }
}