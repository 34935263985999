@use '../../assets/scss/palette.scss' as palette;

#dashboard {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1em;
    width: 100%;
    > a {
        padding: 1em;
        background-color: lighten(palette.$secondary-color, 25%);
        box-sizing: border-box;
        color: darken(palette.$body-text-color, 20%);
        text-decoration: none;
        box-shadow: lighten(palette.$body-text-color, 20%) 1px 2px 3px;
        > h4 {
            margin: 0 0 0.5em;
            text-transform: capitalize;
        }
        > div {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 1em;
            text-align: center;
            .numbers {
                margin-top: 0.2em;
                padding: 0.1em 0;
                font-size: 2.5em;
                font-weight: 700;
                &.ok {
                    background-color: lighten(palette.$ok-color, 20%);
                }
                &.warn {
                    background-color: lighten(palette.$warn-color, 20%);
                }
                &.error {
                    background-color: lighten(palette.$error-color, 20%);
                }
            }
        }
    }
}