#signature-container {
    position: relative;
    #signature-canvas {
        width: 100%;
        height: 12em;
        background-color: #fff;
        box-shadow: inset 0 0 20px #e7e7e7;
        border: 1px solid #ccc;
    }
    button {
        position: absolute;
        top: 0.5em;
        right: 0.4em;
        line-height: 1;
    }
    hr {
        position: absolute;
        bottom: 3em;
        left: 3em;
        right: 3em;
    }
}