@use '../../../assets/scss/palette.scss' as palette;

#menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    touch-action: none;
    z-index: 1;
    &.hide {
        pointer-events: none;
        > .inner {
            > .panel {
                left: -100%;
                transition: all ease-in-out 0.2s 0.1s;
            }
            > .screen {
                background-color: transparentize(palette.$body-text-color, 1);
                transition: all ease-in-out 0.2s;
            }
        }
    }
    > .inner {
        position: relative;
        width: 100%;
        max-width: 500px;
        height: 100%;
        margin: 0 auto;
        overflow-x: hidden;
        > .panel {
            position: absolute;
            left: 0;
            width: 80%;
            height: 100%;
            padding: 1em;
            box-sizing: border-box;
            background-color: #fff;
            box-shadow: palette.$body-text-color 1px 1px 20px;
            transition: all ease-in-out 0.2s;
            z-index: 1;
            .icon {
                font-size: 1.5em;
                color: palette.$primary-color;
            }
            > ul {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-gap: 0.5em;
                margin: 3em 0 0;
                padding: 0;
                list-style: none;
                > li {
                    > a {
                        display: block;
                        padding: 2em 1em;
                        text-align: center;
                        color: #fff;
                        background-color: palette.$primary-color;
                        text-decoration: none;
                        &.active {
                            background-color: lighten(palette.$primary-color, 20%);
                            font-weight: 700;
                        }
                    }
                }
            }
        }
        > .screen {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: transparentize(palette.$body-text-color, 0.5);
            cursor: pointer;
            transition: all ease-in-out 0.2s 0.1s;
            z-index: 0;
        }
    }
}
