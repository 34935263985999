@use '../../../assets/scss/palette.scss' as palette;

#reader {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    pointer-events: none;
    z-index: 1;
    transition: all ease-in-out 0.3s;
    &.shown {
        opacity: 1;
        pointer-events: all;
    }
    > .inner {
        position: relative;
        display: grid;
        grid-template-rows: 240px auto 3em;
        width: 100%;
        max-width: 500px;
        height: 100%;
        margin: 0 auto;
        background-color: #ccc;
        box-sizing: border-box;
        overflow-x: hidden;
        > #quagga {
            height: 100%;
            video {
                width: 100%;
                height: 100%;
            }
            canvas {
                display: none;
            }
        }
        > .title {
            text-align: center;
            > h3 {
                margin-top: 1em;
                margin-bottom: 0.5em;
            }
        }
        > .feedback {
            text-align: center;
            padding: 1em;
            overflow-y: scroll;
            > .action-message {
                display: none;
            }
            > #scannedLocation {
                border-color: #000;
            }
            > .message {
                &.ok {
                    color: darken(palette.$ok-color, 5%);
                }
                &.warn {
                    color: palette.$warn-color;
                }
                &.error {
                    color: palette.$error-color;
                }
            }
        }
        > button {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
        }
    }
}