@use '../../../assets/scss/palette.scss' as palette;

#loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    transition: all ease-in-out 0.2s;
    &.hide {
        opacity: 0;
        pointer-events: none;
    }
    > .inner {
        position: relative;
        display: grid;
        width: 100%;
        max-width: 500px;
        height: 100%;
        margin: 0 auto;
        padding: 2em;
        box-sizing: border-box;
        overflow-x: hidden;
        color: #fff;
        background-color: transparentize(palette.$body-text-color, 0.5);
        font-size: 1em;
        text-align: center;
        align-content: center;
    }
}