@use '../../assets/scss/palette.scss' as palette;

header {
    position: fixed;
    top: 0;
    left: 0;
    height: 5.5em;
    width: 100%;
    > .inner {
        position: relative;
        width: 100%;
        max-width: 500px;
        height: 100%;
        margin: auto;
        padding: 1em;
        box-sizing: border-box;
        background-color: #e7e7e7;
        > .icon {
            font-size: 1.5em;
            color: palette.$primary-color;
        }
        .training-msg {
            vertical-align: top;
            margin-left: 1em;
            padding: 0.1em 0.6em;
            font-size: 1em;
            color: palette.$ok-color;
            background-color: #fff;
            box-shadow: lighten(#000, 70%) 1px 1px 3px;
            border-radius: 1em;
            text-transform: uppercase;
        }
        img {
            position: absolute;
            top: 0.8em;
            right: 1em;
            height: 1.8em;
        }
        > h1 {
            position: absolute;
            top: 3.2em;
            left: 0;
            width: 100%;
            right: 0;
            margin: 0;
            padding: 0.2em 1em;
            box-sizing: border-box;
            font-size: 1.1em;
            font-weight: 400;
            color: darken(palette.$body-text-color, 20%);
            background-color: lighten(palette.$body-text-color, 40%);
            > .icon {
                font-size: 0.7em;
                padding: 0 0.5em;
            }
        }
    }
}