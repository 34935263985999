@use '../assets/scss/palette.scss' as palette;

#root {
    width: 100%;
    max-width: 500px;
    height: 100%;
    max-height: 1000px;
    margin: auto;
    box-shadow: lighten(#000, 40%) 0px 0px 10px;
    user-select: none;
    > main {
        padding: 5.5em 0 0;
        height: 100%;
        box-sizing: border-box;
        touch-action: none;
        transition: all ease-in-out 0.3s;
        &.hide {
            opacity: 0;
            pointer-events: none;
        }
        > div {
            padding: 1em 1em 5.5em;
            background-color: #fff;
            height: 100%;
            box-sizing: border-box;
            overflow-y: scroll;
            &::-webkit-scrollbar{
                display: none;
            }
        }
    }
}