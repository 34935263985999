@use './assets/scss/palette.scss' as palette; 

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: palette.$body-text-color;
  background-color: palette.$primary-color;
  > #root {
    height: 100%;
  }
}

h1 {
  font-size: 1.6em;
}
h2 {
  font-size: 1.4em;
}
h3 {
  font-size: 1.2em;
}
h4 {
  font-size: 1.1em;
}

.link, a {
  cursor: pointer;
  color: palette.$link-color;
  &:hover, &:active, &.focus {
    color: lighten(palette.$link-color, 20%);
  }
  &:visited {
    color: palette.$link-color;
  }
  &.confirm {
    color: darken(palette.$ok-color, 10%);
  }
  &.danger {
    color: palette.$error-color;
  }
}

hr {
  border-color: #ccc;
  border-width: 0.2em;
  border-style: solid;
  &.dashed {
    border-width: 0.1em;
    border-style: dashed;
  }
}

.uppercase {
  text-transform: uppercase;
}

.underline {
  text-decoration: underline;
}

.icon {
  transition: all ease-in-out 0.3s;
  &.rotated {
    transform: rotate(180deg);
  }
}

form {
  .form-control {
    margin-bottom: 1em;
    > label {
      display: block;
      font-weight: 500;
    }
    > input, > select {
      display: block;
      width: 100%;
      padding: 0.2em 0.4em;
      box-sizing: border-box;
      font-size: 1.2em;
    }
  }
}

button {
  padding: 0.6em 1em;
  font-size: 1.2em;
  font-weight: 700;
  color: #fff;
  background-color: palette.$primary-color;
  border: none;
  text-transform: uppercase;
  cursor: pointer;
  &:disabled {
    opacity: 0.4;
    background-color: darken(palette.$primary-color, 30%);
  }
  &.small {
    padding: 0.3em 0.5em;
    font-size: 1em;
    font-weight: 100;
  }
  &.secondary {
    background-color: palette.$secondary-color;
  }
  &.confirm {
    background-color: darken(palette.$ok-color, 10%);
  }
  &.danger {
    background-color: palette.$error-color;
  }
}

.tabs {
  position: relative;
  list-style: none;
  margin: 0 -1em 1em;
  padding: 0 1em;
  border-bottom: 1px solid palette.$primary-color;
  > li {
    display: inline-block;
    padding: 0.5em 1em;
    margin: 0 0.2em 0 0;
    color: #fff;
    background-color: palette.$primary-color;
    border: 1px solid palette.$primary-color;
    cursor: pointer;
    &.active {
      padding: 0.5em 1em 0.6em;
      margin-bottom: -0.1em;
      border-bottom: 1px solid #fff;
      color: palette.$body-text-color;
      background-color: #fff;
      font-weight: 700;
      cursor: default;
    }
  }
}

.card {
  display: block;
  margin-bottom: 0.5em;
  border: 1px solid lighten(palette.$body-text-color, 30%);
  cursor: pointer;
  &.ok {
    > div {
      &.bottom {
        background-color: lighten(palette.$ok-color, 40%);
      }
    }
  }
  &.warn {
    > div {
      &.bottom {
        background-color: lighten(palette.$warn-color, 40%);
      }
    }
  }
  > div {
    pointer-events: none;
    padding: 1em;
    &.bottom {
      padding: 0.5em 1em;
      background-color: lighten(palette.$secondary-color, 25%);
    }
    .number {
      display: inline-block;
      width: 1.5em;
      padding: 0.2em 0;
      margin-right: 0.5em;
      line-height: 1;
      color: #fff;
      background-color: palette.$primary-color;
      border-radius: 100%;
      text-align: center;
      font-size: 0.9em;
    }
    .stop-type {
      float: right;
      text-transform: uppercase;
      font-size: 0.8em;
      line-height: 2;
    }
    .postcode {
      float: right;
      > .icon {
        font-size: 0.8em;
        margin-right: 0.3em;
      }
    }
  }
}