@use '../../../assets/scss/palette.scss' as palette;

#login {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: all ease-in-out 0.3s;
    &.hide {
        opacity: 0;
        pointer-events: none;
    }
    > .inner {
        position: relative;
        width: 100%;
        max-width: 500px;
        height: 100%;
        margin: 0 auto;
        padding: 2em;
        box-sizing: border-box;
        overflow-x: hidden;
        background-color: lighten(palette.$secondary-color, 20%);
        > img {
            margin-bottom: -1.5em;
        }
        > h1 {
            text-align: right;
            margin-bottom: 2em;
            margin-right: 0.2em;
            font-size: 1.5em;
            color: palette.$primary-color;
        }
        .show-password {
            float: right;
            > .icon {
                vertical-align: middle;
                margin-right: 0.5em;
            }
        }
    }
}