@use '../../assets/scss/palette.scss' as palette;

#settings {
    > .setting {
        display: grid;
        grid-template-columns: 2fr 3fr;
        grid-gap: 1em;
        padding-bottom: 1em;
        > div {
            display: inline-block;
            > .link {
                > .icon, > span {
                    pointer-events: none;
                }
            }
        }
        .icon {
            display: inline-block;
            vertical-align: top;
            margin-top: 0.25em;
            margin-right: 0.5em;
        }
        .form-control {
            display: grid;
            grid-template-columns: 3fr 1em;
            grid-gap: 0.5em;
        }
    }
}